export const Menu = [
  {
    title: "Home",
    path: "1",
    to: "/",
    
  },
  {
    title: "About Us",
    path: "2",
    to: "/about-us",
    
  },
  

  {
    title: "Services",
    path: "4",
    to: "/services",
  

  },
  
  {
    title: "Portfolio",
    to: "/portfolio",
    path: "3",
    
   
  },
  {
    title: "Blog",
    path: "5",
    to: "/blog",
   
  },
  // {
  //   title: "Location",
  //   path: "6",
  //   to: "/UPVC-windows-in-mohali",
  // },
  

  {
    title: "Location",
    path: "7",
    to: "/aluminium-windows-and-doors-in-mohali",
 
    dropmenu:"sub-menu-down",
    content:[{subtitle:"Punjab" , to:"/construction-company-in-Mohali"},{subtitle:"Mohali" , to:"/aluminium-windows-and-doors-in-mohali"} ]
  },

  
  

  // {
  //   title: "Location3",
  //   path: "8",
  //   to: "/aluminium-windows-and-doors",
  // },

  
  

  // {
  //   title: "Location4",
  //   path: "9",
  //   to: "/upvc-windows-and-doors",
  // },

  // {
  //   title: "Location5",
  //   path: "10",
  //   to: "/construction-company-in-Mohali",
  // },
  {
    title: "Contact Us",
    to: "/contact-us",
    path: "5",
  },
];
