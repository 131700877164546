import React from 'react';
import CommanBanner from '../elements/CommanBanner';
import { IMAGES } from '../constants/theme';
import Working from '../components/Working';
import Projects from '../components/Projects';
// import Testimonial1 from '../components/Testimonial1';
// import Testimonial2 from '../components/Testimonial2';
import Faq from '../components/Faq';
import {motion} from 'framer-motion'
// import { Link } from 'react-router-dom'
import LargeSidebar from '../components/LargeSidebar'

import { Helmet } from 'react-helmet';




const Landingpage4 = () => {
    return (
        <>

<Helmet>

<title>  High-Quality UPVC Windows and Doors for Residential & Commercial  </title>


<meta name="description" content="Explore a wide range of high-quality UPVC windows and doors. Durable, energy-efficient solutions for residential and commercial properties. Custom designs and expert installation services. "/>
<meta name="Keyword" content="UPVC windows, UPVC doors, energy-efficient, residential, commercial, custom designs, installation services"/>



</Helmet>
        <div className="page-content bg-white">
            <CommanBanner mainTitle="Welcome to Impresa Build Tech"  bgImage={IMAGES.bannerbg3} showPlush = {true} />
            <div className="content-inner">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 col-lg-8">
                            <div className="dz-card blog-single style-1">
                                {/* <div className="dz-media">
                                    <img src={IMAGES.blogGridPic2} alt="" />
                                </div> */}
                                <div className="dz-info">
                                    {/* <div className="dz-meta">
                                        <ul>
                                            <li>April 26, 2021</li>
                                            <li className="post-user">By <Link>John Doe</Link></li>
                                        </ul>
                                    </div> */}
                                    <h2 className="dz-title">Welcome to Impresa Build Tech</h2>
                                    <div className="dz-post-text">
                                        <p>
                                        Discover the Perfect Blend of Style and Durability with Our UPVC Windows and Doors</p>

                                        <p>
                                        Welcome to Impresabuild Tech, your premier destination for high-quality UPVC windows and doors. We take pride in offering a comprehensive range of products that combine aesthetic appeal with unmatched durability.</p>
                                        {/* <blockquote className="block-quote style-1">
                                            <p>“ Mauris justo augue, laoreet quis faucibus et, ultrices eu ante. Duis magna eratut dui eu maximus metus bibendum suscipit ”</p>
                                            <cite>Visva</cite>
                                        </blockquote> */}
                                        {/* <div className="alignleft w-50">
                                            <img alt="" src={IMAGES.blogGridPic2} className="w-100" />
                                        </div> */}
                                        <h2>Our Products</h2>
                                        {/* <p> UPVC windows are excellent at insulating your home. They have good thermal properties, which help to keep your home warm in the winter and cool in the summer. This can lead to energy savings and lower heating and cooling costs.</p> */}
                                        <h4>	UPVC Windows:</h4>
                                        <p>Enhance the beauty of your home or office with our stylish and energy-efficient UPVC windows. Choose from a variety of designs that perfectly complement your interior and exterior spaces. Our windows are crafted with precision to ensure longevity and optimal performance.</p>
                                        <h4>	UPVC Doors:</h4>
                                        <p>Make a lasting impression with our premium UPVC doors. From elegant entrance doors to secure and functional patio doors, we have a diverse selection to suit your preferences. Our doors are not just aesthetically pleasing but also provide exceptional insulation and security.</p>
                                        {/* <h4>			Sleek and Modern Design:</h4>
                                        <p>Elevate the aesthetic appeal of your property with our sleek and modern designs. Our aluminium windows and doors seamlessly blend functionality with contemporary style, enhancing the overall look of your home or business.</p>
                                        <h4>			Customization Options:</h4>
                                        <p>Tailor our products to your unique preferences. Choose from a variety of styles, finishes, and configurations to complement your architectural vision. Our team is dedicated to bringing your design ideas to life.</p>
                                        <h4>				Noise Reduction:</h4>
                                        <p>Experience tranquility within your space. Our aluminium windows and doors provide excellent noise reduction, creating a peaceful and quiet atmosphere for you to enjoy.</p>
                                        
                                        <h4>				Security Features:</h4>
                                        <p>Your safety is our priority. Our windows and doors are equipped with advanced security features, offering you peace of mind and safeguarding your property against intruders</p>
                                       
                                        <h4>				Expert Installation:</h4>
                                        <p>Trust our team of skilled professionals to handle the installation with precision and care. We ensure a seamless fit, optimal functionality, and a hassle-free experience for our customers.</p>
                                       
                                         */}
                                        <h2>	Why Choose Impresa Build Tech</h2>
                                        <h4>			Quality Assurance: </h4>
                                        <p>We source the finest UPVC materials to manufacture windows and doors that stand the test of time.</p>
                                        <h4>				Custom Designs: </h4>
                                        <p>Tailor your windows and doors to match your unique style. Our custom design options allow you to create a look that's distinctly yours.</p>
                                       
                                        <h4>				Energy Efficiency:</h4>
                                        <p>Our UPVC products are designed to enhance energy efficiency, keeping your space comfortable while reducing energy costs.</p>
                                       
                                       
                                        <h4>				Expert Installation:</h4>
                                        <p>Count on our skilled professionals for seamless installation services. We ensure that your windows and doors are installed with precision and care.</p>

                                        {/* <h4>				Tilt and Turn Windows:</h4>
                                        <p>Versatile and easy to clean, our tilt and turn windows provide multiple opening options, allowing you to control ventilation and maintenance with ease.</p>
                                        <h4>					Fixed Windows:</h4>
                                        <p>Create stunning panoramic views with our fixed windows, showcasing the beauty of Mohali's surroundings.</p> */}
                                        {/* <h2>Transform Your Living Spaces</h2>
                                      
                                        <p>Investing in premium aluminium windows and doors is an investment in the comfort, aesthetics, and value of your property. Whether you're renovating your home or planning a commercial project, the right windows and doors can make a significant difference.</p>
 */}

                                        <h2>Get in Touch</h2>
                                      
                                      <p>Ready to transform your space with our UPVC windows and doors? Contact us today for expert advice, product details, and a personalized quote. Your satisfaction is our priority.</p>

                                        {/* <h4>				Professional Installation:</h4>
                                        <p>Our team of skilled professionals ensures that your UPVC windows are installed with precision and care, guaranteeing optimal performance.</p> */}

                                        {/* <h4>				Customer Satisfaction:</h4>
                                        <p>Customer satisfaction is our priority. We are committed to providing excellent service from consultation to installation and beyond.</p>
                                        <p>Elevate your home with the finest UPVC windows in Mohali. Impresa Build Tech today for a consultation and transform your living space into a haven of beauty, comfort, and efficiency.</p> */}

                                    </div>
                                  
                                </div>
                            </div>
                           

                           
                            
{/* 
                            <div className="comment-respond style-1" id="respond">
                                <div className="widget-title">
                                    <h4 className="title">Leave Your Comment</h4>
                                    <div className="dz-separator style-1 text-primary mb-0"></div>
                                </div>
                                <form className="comment-form" id="commentform" method="post">
                                    <p className="comment-form-author">
                                        <label htmlFor="author">Name <span className="required">*</span></label>
                                        <input type="text" name="Author" placeholder="Author" id="author" />
                                    </p>
                                    <p className="comment-form-email">
                                        <label htmlFor="email">Email <span className="required">*</span></label>
                                        <input type="text" placeholder="Email" name="email" id="email" />
                                    </p>
                                    <p className="comment-form-author w-100">
                                        <label htmlFor="author">Subtitale <span className="required">*</span></label>
                                        <input type="text" name="Author" placeholder="Subtitale" id="subtitale" />
                                    </p>
                                    <p className="comment-form-comment">
                                        <label htmlFor="comment">Comment</label>
                                        <textarea rows="8" name="comment" placeholder="Comment" id="comment"></textarea>
                                    </p>
                                    <p className="form-submit">
                                        <button type="submit" className="btn shadow-primary btn-primary btn-icon btn-rounded" id="submit">POST NOW
                                            <i className="fas fa-caret-right ms-1"></i>
                                        </button>
                                    </p>
                                </form>
                            </div> */}





                        </div>
                        <LargeSidebar />
                    </div>
                </div>
            </div>
        </div>

    </>
    )
}
export default Landingpage4;